@import "./variables";

.alert {
  border: 1px solid transparent;
  border-radius: $border-radius;
  margin-bottom: $spacer-2;
  position: relative;
  padding: $spacer $spacer-2;

  &.alert-full-bleed {
    border-radius: 0;
  }

  &.alert-secondary {
    color: $color-white;
    background-color: $color-secondary;
  }

  &.alert-trinary {
    color: $color-white;
    background-color: $color-trinary;
  }

  &.alert-success {
    color: $color-white;
    background-color: $color-success;
  }

  &.alert-info {
    color: $color-white;
    background-color: $color-info;
  }

  &.alert-warning {
    color: $color-white;
    background-color: $color-warning;
  }

  &.alert-danger {
    color: $color-white;
    background-color: $color-danger;
  }
}
