h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  display: block;
  margin: 0;
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-normal;
  line-height: $line-height-header-base;
  width: 100%;

  &.text-page-header {
    border-bottom: $border-width solid $border-color;
    margin-top: $spacer * 4;
    padding-bottom: $spacer-1;
  }
}

h1, .h1 { font-size: $h1-font-size; margin-bottom: $spacer-4; }
h2, .h2 { font-size: $h2-font-size; margin-bottom: $spacer-3; }
h3, .h3 { font-size: $h3-font-size; margin-bottom: $spacer-2; }
h4, .h4 { font-size: $h4-font-size; margin-bottom: $spacer-1; }
h5, .h5 { font-size: $h5-font-size; margin-bottom: ($spacer * 0.5); }
h6, .h6 { font-size: $h6-font-size; margin-bottom: 0; }

@media (max-width: $breakpoint-md) {
  h1, .h1 { font-size: ($h1-font-size * 0.8); margin-bottom: $spacer-3; }
  h2, .h2 { font-size: ($h2-font-size * 0.8); margin-bottom: $spacer-2; }
  h3, .h3 { font-size: ($h3-font-size * 0.8); margin-bottom: $spacer-1; }
}

a {
  color: $link-color;
  text-decoration: $link-decoration;
  word-wrap: break-word;

  &:hover, &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:active {
    color: $link-active-color;
    text-decoration: $link-active-decoration;
  }
}

/* Text Alignment */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/* Text Wrapping */
.text-no-wrap {
  white-space: nowrap;
}

/* Text Coloring */
.text-muted-100 { color: $gray-100; }
.text-muted-200 { color: $gray-200; }
.text-muted-300 { color: $gray-300; }
.text-muted-400 { color: $gray-400; }
.text-muted-500 { color: $gray-500; }
.text-muted-600 { color: $gray-600; }
.text-muted-700 { color: $gray-700; }
.text-muted-800 { color: $gray-800; }
.text-muted-900 { color: $gray-900; }

.text-color-primary { color: $color-primary; }
.text-color-secondary { color: $color-secondary; }
.text-color-trinary { color: $color-trinary; }

.text-color-success { color: $color-success; }
.text-color-info { color: $color-info; }
.text-color-warning { color: $color-warning; }
.text-color-danger { color: $color-danger; }

.text-color-brand-facebook { color: $color-brand-facebook; }
.text-color-brand-reddit { color: $color-brand-reddit; }
.text-color-brand-twitter { color: $color-brand-twitter; }

/* Text Sizing */
.text-lead {
  font-size: $font-size-lg;
  font-weight: $font-weight-normal;
}

.text-small {
  font-size: 0.8rem;
  line-height: $spacer * 2;
}
