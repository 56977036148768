@import "../../../shared_scss/variables";
@import "../../../shared_scss/media_queries";

// Static Variables
$admin-header-height: $spacer * 10;
$admin-nav-width: 300px;

// Main Layout Grid
#admin-container {
  // Flexbox Fallback
  display: flex;
  flex-wrap: wrap;

  // Grid
  display: grid;
  height: 100vh;

  grid-template-rows:     $admin-header-height 1fr;
  grid-template-columns:  $admin-nav-width 1fr;
  grid-template-areas:    "header header"
                          "nav    main";

  header {
    background-color: $color-white;
    //border-bottom: 1px solid $gray-100;
    //box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-basis: 100%;
    grid-area: header;
    height: $admin-header-height;
    position: relative;

    .logo {
      display: flex;
      flex: 0 0 $admin-nav-width;
      height: $admin-header-height;
      justify-content: center;
      align-items: center;
      position: relative;
      margin: 0;
      color: #fff;
      background-color: $color-white;
      font-size: 1rem;

      h4 {
        margin: 0;
      }
    }

    .toolbar {
      background-color: $color-primary;
      display: flex;
      flex: 1;
      //justify-content: space-between;
      justify-content: flex-end;
      align-items: center;
      padding: 0 ($spacer * 4) 0 0;

      .log-out {
        border-radius: $border-radius;
        color: $color-white;
        padding: ($spacer * 0.5) $spacer;
        transition: background-color $transition-fast;

        &:hover, &:focus {
          background-color: $color-danger;
          text-decoration: none;
        }

        &:active {
          background-color: $color-danger-dark;
        }
      }
    }
  }

  nav {
    background-color: $color-white;
    flex: 0 0 $admin-nav-width;
    grid-area: nav;

    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin-bottom: $spacer;

        a {
          display: block;
          padding: $spacer $spacer $spacer ($spacer * 4);
          color: $gray-700;
          text-decoration: none;

          &:hover,
          &:focus {
            color: $color-primary;
          }

          &.active {
            background-color: $color-primary;
            border-radius: $border-radius;
            color: $color-white;
            cursor: default;
            margin: 0 $spacer-2;
            padding: $spacer $spacer $spacer ($spacer * 2);
          }
        }
      }
    }
  }

  main {
    background: $gray-50;
    background: linear-gradient(180deg, $color-primary 0%, $color-primary-light 300px, $gray-50 300px, $gray-100 100%);
    //box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    flex: 1;
    grid-area: main;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 ($spacer * 4) ($spacer * 4) ($spacer * 4);
    -webkit-overflow-scrolling: touch;

    h1.page-title {
      color: $color-white;
      text-shadow: 0 2px 2px rgba(0,0,0,0.03), 0 6px 15px rgba(0,0,0,0.07);
    }

    footer {
      color: $gray-400;
      margin-bottom: $spacer * 2;
      margin-top: $spacer * 4;
      padding: 0 ($spacer * 2);
      text-align: center;
    }
  }

}

.card {
  background-color: #FFF;
  border-radius: $border-radius;
  //height: 100%;
  padding: $spacer-2;
  box-shadow: 0 2px 2px rgba(0,0,0,0.03), 0 6px 15px rgba(0,0,0,0.07);
  //transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  & + .card {
    margin-top: $spacer-3;
  }
}

.inline-edit-action {
  color: $color-warning;
  font-size: $line-height-base;
}