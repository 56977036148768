.m-t-0 { margin-top: 0; }
.m-t-1 { margin-top: $spacer; }
.m-t-2 { margin-top: $spacer * 2; }
.m-t-3 { margin-top: $spacer * 3; }
.m-t-4 { margin-top: $spacer * 4; }
.m-t-5 { margin-top: $spacer * 5; }
.m-t-10 { margin-top: $spacer * 10; }

.m-r-0 { margin-right: 0; }
.m-r-1 { margin-right: $spacer; }
.m-r-2 { margin-right: $spacer * 2; }
.m-r-3 { margin-right: $spacer * 3; }
.m-r-4 { margin-right: $spacer * 4; }
.m-r-5 { margin-right: $spacer * 5; }

.m-b-0 { margin-bottom: 0; }
.m-b-1 { margin-bottom: $spacer; }
.m-b-2 { margin-bottom: $spacer * 2; }
.m-b-3 { margin-bottom: $spacer * 3; }
.m-b-4 { margin-bottom: $spacer * 4; }
.m-b-5 { margin-bottom: $spacer * 5; }
.m-b-10 { margin-bottom: $spacer * 10; }

.m-l-0 { margin-left:0; }
.m-l-1 { margin-left: $spacer; }
.m-l-2 { margin-left: $spacer * 2; }
.m-l-3 { margin-left: $spacer * 3; }
.m-l-4 { margin-left: $spacer * 4; }
.m-l-5 { margin-left: $spacer * 5; }

.p-t-10 { padding-top: $spacer * 10; }

.p-b-10 { padding-bottom: $spacer * 10; }


@media (min-width: $breakpoint-sm) {
  .m-sm-t-0 { margin-top: 0; }
  .m-sm-t-1 { margin-top: $spacer; }
  .m-sm-t-2 { margin-top: $spacer * 2; }
  .m-sm-t-3 { margin-top: $spacer * 3; }
  .m-sm-t-4 { margin-top: $spacer * 4; }
  .m-sm-t-5 { margin-top: $spacer * 5; }

  .m-sm-r-0 { margin-right: 0; }
  .m-sm-r-1 { margin-right: $spacer; }
  .m-sm-r-2 { margin-right: $spacer * 2; }
  .m-sm-r-3 { margin-right: $spacer * 3; }
  .m-sm-r-4 { margin-right: $spacer * 4; }
  .m-sm-r-5 { margin-right: $spacer * 5; }

  .m-sm-b-0 { margin-bottom: 0; }
  .m-sm-b-1 { margin-bottom: $spacer; }
  .m-sm-b-2 { margin-bottom: $spacer * 2; }
  .m-sm-b-3 { margin-bottom: $spacer * 3; }
  .m-sm-b-4 { margin-bottom: $spacer * 4; }
  .m-sm-b-5 { margin-bottom: $spacer * 5; }

  .m-sm-l-0 { margin-left:0; }
  .m-sm-l-1 { margin-left: $spacer; }
  .m-sm-l-2 { margin-left: $spacer * 2; }
  .m-sm-l-3 { margin-left: $spacer * 3; }
  .m-sm-l-4 { margin-left: $spacer * 4; }
  .m-sm-l-5 { margin-left: $spacer * 5; }
}

@media (min-width: $breakpoint-md) {
  .m-md-t-0 { margin-top: 0; }
  .m-md-t-1 { margin-top: $spacer; }
  .m-md-t-2 { margin-top: $spacer * 2; }
  .m-md-t-3 { margin-top: $spacer * 3; }
  .m-md-t-4 { margin-top: $spacer * 4; }
  .m-md-t-5 { margin-top: $spacer * 5; }

  .m-md-r-0 { margin-right: 0; }
  .m-md-r-1 { margin-right: $spacer; }
  .m-md-r-2 { margin-right: $spacer * 2; }
  .m-md-r-3 { margin-right: $spacer * 3; }
  .m-md-r-4 { margin-right: $spacer * 4; }
  .m-md-r-5 { margin-right: $spacer * 5; }

  .m-md-b-0 { margin-bottom: 0; }
  .m-md-b-1 { margin-bottom: $spacer; }
  .m-md-b-2 { margin-bottom: $spacer * 2; }
  .m-md-b-3 { margin-bottom: $spacer * 3; }
  .m-md-b-4 { margin-bottom: $spacer * 4; }
  .m-md-b-5 { margin-bottom: $spacer * 5; }

  .m-md-l-0 { margin-left:0; }
  .m-md-l-1 { margin-left: $spacer; }
  .m-md-l-2 { margin-left: $spacer * 2; }
  .m-md-l-3 { margin-left: $spacer * 3; }
  .m-md-l-4 { margin-left: $spacer * 4; }
  .m-md-l-5 { margin-left: $spacer * 5; }
}

@media (min-width: $breakpoint-lg) {
  .m-lg-t-0 { margin-top: 0; }
  .m-lg-t-1 { margin-top: $spacer; }
  .m-lg-t-2 { margin-top: $spacer * 2; }
  .m-lg-t-3 { margin-top: $spacer * 3; }
  .m-lg-t-4 { margin-top: $spacer * 4; }
  .m-lg-t-5 { margin-top: $spacer * 5; }

  .m-lg-r-0 { margin-right: 0; }
  .m-lg-r-1 { margin-right: $spacer; }
  .m-lg-r-2 { margin-right: $spacer * 2; }
  .m-lg-r-3 { margin-right: $spacer * 3; }
  .m-lg-r-4 { margin-right: $spacer * 4; }
  .m-lg-r-5 { margin-right: $spacer * 5; }

  .m-lg-b-0 { margin-bottom: 0; }
  .m-lg-b-1 { margin-bottom: $spacer; }
  .m-lg-b-2 { margin-bottom: $spacer * 2; }
  .m-lg-b-3 { margin-bottom: $spacer * 3; }
  .m-lg-b-4 { margin-bottom: $spacer * 4; }
  .m-lg-b-5 { margin-bottom: $spacer * 5; }

  .m-lg-l-0 { margin-left:0; }
  .m-lg-l-1 { margin-left: $spacer; }
  .m-lg-l-2 { margin-left: $spacer * 2; }
  .m-lg-l-3 { margin-left: $spacer * 3; }
  .m-lg-l-4 { margin-left: $spacer * 4; }
  .m-lg-l-5 { margin-left: $spacer * 5; }
}

@media (min-width: $breakpoint-xl) {
  .m-lg-t-0 { margin-top: 0; }
  .m-lg-t-1 { margin-top: $spacer; }
  .m-lg-t-2 { margin-top: $spacer * 2; }
  .m-lg-t-3 { margin-top: $spacer * 3; }
  .m-lg-t-4 { margin-top: $spacer * 4; }
  .m-lg-t-5 { margin-top: $spacer * 5; }

  .m-lg-r-0 { margin-right: 0; }
  .m-lg-r-1 { margin-right: $spacer; }
  .m-lg-r-2 { margin-right: $spacer * 2; }
  .m-lg-r-3 { margin-right: $spacer * 3; }
  .m-lg-r-4 { margin-right: $spacer * 4; }
  .m-lg-r-5 { margin-right: $spacer * 5; }

  .m-lg-b-0 { margin-bottom: 0; }
  .m-lg-b-1 { margin-bottom: $spacer; }
  .m-lg-b-2 { margin-bottom: $spacer * 2; }
  .m-lg-b-3 { margin-bottom: $spacer * 3; }
  .m-lg-b-4 { margin-bottom: $spacer * 4; }
  .m-lg-b-5 { margin-bottom: $spacer * 5; }

  .m-lg-l-0 { margin-left:0; }
  .m-lg-l-1 { margin-left: $spacer; }
  .m-lg-l-2 { margin-left: $spacer * 2; }
  .m-lg-l-3 { margin-left: $spacer * 3; }
  .m-lg-l-4 { margin-left: $spacer * 4; }
  .m-lg-l-5 { margin-left: $spacer * 5; }
}
