::selection {
	background: $color-primary;
	color: $color-white;
	text-shadow: none;
}

hr {
  border: 0;
  border-top: $border-width solid transparent;
  height: 0;
  margin: ($spacer-4) 0;

  &.hr-line {
    border-top: $border-width solid $border-color;
  }

  &.hr-small {
    margin: ($spacer-2) 0;
  }

  &.hr-large {
    margin: ($spacer * 6) 0;
  }
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.border-radius {
  border-radius: $border-radius;
}