/* Standard Buttons */

.btn {
  background-color: $color-primary;
  border-radius: $border-radius;
  box-sizing: border-box;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-weight: $font-weight-normal;
  line-height: $spacer * 3;
  padding: ($spacer - 2px) ($spacer * 2);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 2px solid transparent;
  transition: $transition-base;

  // Share hover and focus styles
  &:hover, &:focus {
    background-color: $color-primary-light;
    box-shadow: $box-shadow-3;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 1;
  }

  &:hover {
    &:focus,
    &.focus {
      outline: 0;
    }
  }

  // Active state (click or touch event)
  &:active {
    background-color: $color-primary-dark;
    box-shadow: $box-shadow-3;
    text-decoration: none;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: 0.5;
    box-shadow: none;

    &:hover, &:focus, &:active {
      background-color: $color-primary;
      cursor: not-allowed;
    }
  }

  /* Button Sizes */
  &.btn-sm {
    font-size: 1rem;
    line-height: $spacer * 2;
    padding: (($spacer * 0.5) - 2px) $spacer;
  }

  /* Button Styles */
  &.btn-muted {
    background-color: $gray-700;

    &:hover, &:focus {
      background-color: $gray-600;
    }

    &:active {
      background-color: $gray-800;
    }
  }

  &.btn-warning {
    background-color: $color-warning;

    &:hover, &:focus {
      background-color: $color-warning-light;
    }

    &:active {
      background-color: $color-warning-dark;
    }
  }

  /* Block Buttons */
  &.btn-block {
    display: block;
    width: 100%;

    // Vertically space out multiple block buttons
    + .btn-block {
      margin-top: $spacer * 2;
    }
  }
}



