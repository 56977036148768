@mixin phone {
  @media (max-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: #{$breakpoint-xl}) {
    @content;
  }
}

@mixin desktop-xl {
  @media (min-width: #{$breakpoint-xl + 1px}) {
    @content;
  }
}