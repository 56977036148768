@import "../../../shared_scss/variables";
@import "../../../shared_scss/media_queries";

.pricing-table-container {
  display: flex;

  .package-container {
    flex: 1;
    width: 0;

    &:not(:last-child) {
      margin-right: $spacer * 2;
    }
  }

  .package {
    border: 1px solid $gray-100;
    border-radius: $border-radius;
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    transition: 0.25s;

    &:hover {
      box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
      transform: scale(1.035);
    }

    .header {
      background-color: $gray-800;
      color: #fff;
      font-size: 1.15rem;
      text-align: center;
    }

    .highlight {
      background-color: $color-primary-dark;
    }

    li {
      background-color: #fff;
      border-bottom: 1px solid $gray-100;
      font-size: 0.9rem;
      padding: $spacer;
      text-align: left;
    }

    .gray {
      background-color: $gray-100;
      text-align: center;
    }
  }
  @include tablet {
    flex-wrap: wrap;

    .package-container {
      flex: 0 0 100%;
      margin-bottom: 1em;
      width: 100%;

      &:hover {
        box-shadow: none;
        transform: none;
      }
    }
  }
}
