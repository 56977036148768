/* Labels
–––––––––––––––––––––––––––––––––––––––––––––––––– */

label {
	color: $gray-500;
	cursor: pointer;
	display: inline-block;
	line-height: 1rem;
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
	position: relative;
	user-select: none;
	
	&.label-inline {
		display: inline-block;
		float: left;
		line-height: 1.5rem;
		margin-bottom: 0.75rem;
		margin-right: 1rem;
		margin-top: 0;
		padding-bottom: 0;
	}
	
	&.required {
		&:before {
			color: $color-danger;
			content: '*';
			font-size: 1rem;
			font-weight: 500;
			line-height: 1rem;
			position: absolute;
			right: -0.5rem;
			top: -0.25rem;
			vertical-align: text-top;
		}
	}
}


/* Text Inputs
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.input-text, .input-textarea, .input-select {
	background-color: #FFF;
	border: 2px solid $gray-200;
	border-radius: $border-radius-sm;
	display: block;
	font-size: 1rem;
	line-height: 1rem;
	margin-bottom: 0.75rem;
	padding: 0.75rem;
	position: relative;
	transition: border $transition-fast ease-in-out, box-shadow $transition-fast ease-in-out;
	width: 100%;
	appearance: none;
	-webkit-appearance: none;
}

.input-text:hover, .input-textarea:hover, .input-select:hover {
	border: 2px solid $color-primary-light;
}

.input-text:focus, .input-textarea:focus, .input-select:focus {
	animation: animation-shadow $transition-fast forwards;
	background-color: #FFF;
	border: 2px solid $color-primary;
	box-shadow: 0px 0px 0px 0px rgba($color-primary,1);
	outline: none;
}

.input-textarea {
	height: auto;
	min-height: 4rem;
	padding: 0.75rem;
}


/* Select Inputs
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.input-select {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAMAAAAPdrEwAAAA4VBMVEVHcExVVVtVVX9SVVtSVVxYWGJVVV5SVVtSVVtSVVtTV1xVVWZSVVtSVVtUVltSVVtSVVtTVVtSVlv///9SVVtTVVxSVVxTVVtTVVxUVltTVVtSVVtSVVtSVVtbW1tSVlxSVVtVVV1TV11SVVtSVVxWVlxSVVtTVVtdXV1SVVxmZmZTVVt/f39SVVxTVV1TV1tSVVtVVVxSVVtVVWpSVVtTVVxTVVtSVV1SVVtWVltSVVxYWGNtbW1TVVtSVVtSVVtSVl1SVVtXV11SVVxSVVtVVap/f39TVVtSVVtWVltbW1vqMTEFAAAAS3RSTlMAKgbuxxob4v/nOg/S+Wf60b6aAaeN5oxxcFb+ocYZoHs5T/NmLN6ZE78FlgRpaEDrIdMMsISDV/Yy4xcHn/38R+8m2LcDAl/4OBzZuSmFAAABTklEQVR4Ae3Qg5UEARQEwN4d9dr22bat/PM5c8x7+hVCQSRBCCGEEEKk0vAhnUJgKUXV4ElTlRQC0g0yk4WHbIY0dASSy/NVoQhXxRJflXMIIF3hh2oajtJVfqik4Vutzi+q5tzML/Ua/GrwR9MhPNvkjxZ8avOHU3ixwN868KXb41/VtEPzj14XfvRpNhjij+GAZiP4Mu7RpDkxNZv0xvBpapomhRl8mynQZHoKvs3O0Ww+jXfpeZrNzSKAhUWbcIfmxQUEsrRMu/BJk2bLKwhqtWQNtzaX1hDC+gY9bawjlM0teshvIqTtHbra2UZotV262Kshiv0DOjjYR0SHR7R1dIjIjge0MThGDE5OaXF6gni0z/jHeRuxubjkL5cXiNHVNb9dXyFWuRt+uskhZrcNvmvdIX73D+TDPRIxyWQekZCnJ/wPIYQQQgghXgBuSSjM84MRJQAAAABJRU5ErkJggg==');
	background-position: center right 0.5rem;
	background-repeat: no-repeat;
	background-size: 30px 30px;
	padding-right: 2.5rem;
}

/* Input Placeholder Text 
–––––––––––––––––––––––––––––––––––––––––––––––––– */
::placeholder {
	color: $gray-200;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: $gray-200;
}
::-moz-placeholder { /* Firefox 19+ */
	color: $gray-200;
}
:-ms-input-placeholder { /* IE 10+ */
	color: $gray-200;
}
:-moz-placeholder { /* Firefox 18- */
	color: $gray-200;
}


/* Animations
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@keyframes animation-shadow {
	to {
		box-shadow: 0 0 30px 0 rgba($color-primary,0);
	}
}


/* Form Well
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.well {
	background-color: $gray-100;
	border-radius: $border-radius-sm;
	padding: 1rem;
	
	.btn {
		margin-bottom: 0;
	}
}