@import "../../../shared_scss/variables";
@import "../../../shared_scss/media_queries";

#auth-wrapper {
  //background-image: url('../../assets/img/sign-in-bg.png'), linear-gradient(#FFF, #F2F0FD);
  //background-position: right top;
  //background-repeat: no-repeat;
  //background-size: cover;
  background-color: $gray-100;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 100%;

  .auth-wrapper-content {
    background-color: rgba(255,255,255,0.98);
    border-radius: $border-radius;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: stretch;
    max-width: 80%;
    overflow: hidden;

    @include desktop-xl {
      max-width: 70%;
    }

    @include tablet {
      max-width: 90%;
    }

    @include phone {
      border-radius: 0;
      max-width: 100%;
    }

    .auth-form {
      padding: ($spacer * 8) $spacer-4;
      flex: 2;
    }

    .auth-promo {
      background-color: $color-primary-light;
      color: #FFF;
      flex: 3;
      padding: $spacer-5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
  }
}