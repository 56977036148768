// Grays

// Flat grays
// $gray-50:                     #FAFAFA;
// $gray-100:                    #F5F5F5;
// $gray-200:                    #EEEEEE;
// $gray-300:                    #E0E0E0;
// $gray-400:                    #BDBDBD;
// $gray-500:                    #9E9E9E;
// $gray-600:                    #757575;
// $gray-700:                    #616161;
// $gray-800:                    #424242;
// $gray-900:                    #212121;

// "Cold" Grays
$gray-50:                     #F5F6FA;
$gray-100:                    #EDEFF5;
$gray-200:                    #E1E3ED;
$gray-300:                    #D3D5E0;
$gray-400:                    #AFB2BD;
$gray-500:                    #91949E;
$gray-600:                    #6B6D75;
$gray-700:                    #575961;
$gray-800:                    #3B3C42;
$gray-900:                    #1D1E21;

// Colors
$color-white:                 #FFF;
$color-black:                 #000;

$color-primary:               #2FA9C8;
$color-primary-light:         #3AC1E3;
$color-primary-dark:          #0095BD;

$color-secondary:             #6940E8;
$color-trinary:               #BF68D4;
$color-success:               #A4D24C;
$color-info:                  #3AC1E3;

$color-warning:               #FF973B;
$color-warning-light:         #FFA939;
$color-warning-dark:          #E78C27;

$color-danger:                #E13628;
$color-danger-light:          #FF4433;
$color-danger-dark:           #C72314;

$color-brand-facebook:        #3B5998;
$color-brand-reddit:          #FF4500;
$color-brand-twitter:         #00ACED;


// Body
$body-bg:                     $color-white;
$body-color:                  $gray-900;


// Links
$link-color:                  $color-primary;
$link-decoration:             none;

$link-hover-color:            $color-primary-light;
$link-hover-decoration:       underline;

$link-active-color:           $color-primary-dark;
$link-active-decoration:       underline;


// Animation Transitions
$transition-base: .25s ease-in-out;
$transition-fast: .15s ease-in-out;
$transition-slow: .35s ease-in-out;


// Borders
$border-width:                1px;
$border-color:                $gray-200;

$border-radius:               .25rem;
$border-radius-lg:            .3rem;
$border-radius-sm:            .2rem;


// Breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;


// Dropshadows
$box-shadow-0: 0 1px 0px rgba(0,0,0,0.01);
$box-shadow-1: 0 1px 3px rgba(0,0,0,0.05), 0 1px 2px rgba(0,0,0,0.1);
$box-shadow-2: 0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.1);
$box-shadow-3: 0 10px 20px rgba(0,0,0,0.1), 0 6px 6px rgba(0,0,0,0.1);
$box-shadow-4: 0 14px 28px rgba(0,0,0,0.15), 0 10px 10px rgba(0,0,0,0.1);
$box-shadow-5: 0 19px 38px rgba(0,0,0,0.2), 0 15px 12px rgba(0,0,0,0.1);


// Fonts
$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25);
$font-size-sm:                ($font-size-base * .875);

$font-weight-light:           400;
$font-weight-normal:          500;
$font-weight-bold:            700;

$line-height-base:            20px;
$line-height-header-base:            1.1;

$h1-font-size:                $font-size-base * 3.5;
$h2-font-size:                $font-size-base * 2.5;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;


// Spacers
$spacer:                      10px;
$spacer-1:                    $spacer;
$spacer-2:                    $spacer * 2;
$spacer-3:                    $spacer * 3;
$spacer-4:                    $spacer * 4;
$spacer-5:                    $spacer * 5;


// Textshadows
$text-shadow-1: 0 1px 3px rgba(0,0,0,0.05), 0 1px 2px rgba(0,0,0,0.1);
$text-shadow-2: 0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.1);
$text-shadow-3: 0 10px 20px rgba(0,0,0,0.1), 0 6px 6px rgba(0,0,0,0.1);
$text-shadow-4: 0 14px 28px rgba(0,0,0,0.15), 0 10px 10px rgba(0,0,0,0.1);
$text-shadow-5: 0 19px 38px rgba(0,0,0,0.2), 0 15px 12px rgba(0,0,0,0.1);


// Z-Index
$zindex-canvas-background:    1000;
$zindex-canvas-content:       1010;
$zindex-coin-navbar-fade:     1020;
$zindex-symbol-hover:         1030;
$zindex-input-actions:        1040;
$zindex-loading-wrapper:      1050;
$zindex-loading-logo:         1060;
$zindex-fixed-navbar-wrapper: 1070;
$zindex-fixed-navbar:         1080;
$zindex-modal-backdrop:       1090;
$zindex-modal:                1100;
